import React, {useEffect, useState} from "react";
import {Button, Layout, Avatar,} from 'antd';

import { useNavigate } from "react-router-dom";

import { AntDesignOutlined, GlobalOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../redux/UserSlice";

const {Header} = Layout;

export default function AppHeader(props) {
    const [visibleMenus, setMenus] = useState([])

    const navigate = useNavigate()

    const user = useSelector(state => state.user.user)

    const dispatch = useDispatch()

    useEffect(()=>{

        if (typeof props.menus !== 'undefined') {
            setMenus(props.menus)
        }

    }, [])

    const getMenu = (menu) => {
        switch (menu) {
            case "signin": return "SIGN IN"
            case "login": return "LOG IN"
            case "logout": return "LOG OUT"

            case "user": return "*USER"
        }
    }

    const onRedirect = (menu) => {
        console.log("navigateToPage: " + menu)

        switch (menu) {
            case "signin":
                navigate('/signin')
                break

            case "login":
                navigate('/login')
                break

            case "logout":
                dispatch(logoutUser())
                navigate('/')
                break

            case "user":
                navigate('/user')
                break

            default:
                navigate('/')
        }
    }


    return (
        <div>

            {/** Header **/}
            <Header style={{width: '100%', backgroundColor: '#2b9ee3'}}>

                {/* LOGO */}
                <div style={{float: 'left', cursor: 'pointer',}}
                     onClick={()=>onRedirect('/')}
                >
                            <span style={{fontSize: 50, fontWeight: 'bold', marginRight: 10,}}
                            >
                                <Avatar size={40} icon={<GlobalOutlined />}
                                />
                            </span>
                    <span style={{fontSize: 24, fontWeight: 'bold',}}>
                                {typeof props.title !== 'undefined' ? props.title : ''}
                    </span>
                </div>

                <div style={{float: 'right'}}>
                    {/* User information / Login / SignIn */}
                    {visibleMenus.map((menu, i)=>{

                        if ((typeof user !== 'undefined' && user !== null)
                        && (menu === 'login' || menu === 'signin')) {
                            return
                        }

                        return (
                            <Button key={'function_' + i}
                                style={{marginRight: 10}} ghost
                                    onClick={()=>onRedirect(menu)}
                            >
                                {getMenu(menu)}
                            </Button>
                        )
                    })}
                </div>
            </Header>
        </div>
    )
}
