import React, { useState} from "react";
import {Button, Col, Input, message, Row, Modal, Icon,} from 'antd';

import AppHeader from "../../../components/app_header/AppHeader";


export default function UserSignInView() {
    const [showTermsAndConds, setShowTermsAndConds] = useState(false)
    const [redirectTo, setRedirectTo] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepetition, setPasswordRepetition] = useState('')


    async function onSignIn()
    {
        if (email.length === 0 || password.length === 0) {
            message.error("Please insert your email and password")
            return
        }

        if (password !== passwordRepetition) {
            message.error("Your two passwords must be the same")
            return
        }
    }


    return (
        <div>
            <AppHeader menus={['login']}/>

            <Modal
                title="Terms and Conditions"
                visible={showTermsAndConds}
                onCancel={() => setShowTermsAndConds(false)}
                footer={[
                    <Button key="ok"
                            type="primary"
                            onClick={()=>setShowTermsAndConds(false)}>
                        Agree
                    </Button>,
                ]}
            >
                <p>Term ans Conditions</p>
            </Modal>

            <div style={{margin: '120px 30px 0px 30px'}}>
                <Row>
                    <Col sm={{span: 20, offset: 1}} md={{span: 12, offset: 6}} lg={{span: 6, offset: 9}}>
                        <div style={{padding: '0px 20px 20px 20px', backgroundColor: '#f2f2f2'}}>
                            <h1 style={{textAlign: 'center'}}>Sign in</h1>

                            <div>
                                <p style={{fontWeight: 'bold'}}>First Name</p>
                                <Input placeholder="First Name"
                                       onChange={(e) => setFirstName(e.target.value)}/>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{fontWeight: 'bold'}}>Last Name</p>
                                <Input placeholder="Last Name"
                                       onChange={(e) => setLastName(e.target.value)}/>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{fontWeight: 'bold'}}>Email Address</p>
                                <Input placeholder="Email Address"
                                       onChange={(e) => setEmail(e.target.value)}/>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{fontWeight: 'bold'}}>Password</p>
                                <Input.Password placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div style={{marginTop: 5}}>
                                <Input.Password placeholder="Password"
                                                onChange={(e) => setPasswordRepetition(e.target.value)}/>
                            </div>

                            {/*    Terms AND Conditions */}
                            <Button style={{marginTop: 20, marginLeft: -15}} type="link"
                                onClick={()=>setShowTermsAndConds(true)}
                            >
                                *Terms AND Conditions
                            </Button>

                            <Button type="primary" block style={{marginTop: 20}}
                                onClick={() => onSignIn()}
                            >
                                Sign In
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


