import { configureStore } from '@reduxjs/toolkit';
import userSlice from './redux/UserSlice'
import locationsSlice from './redux/locationsSlice'
import routeCreationSlice from "./redux/RouteCreationSlice";

export default configureStore({
    reducer: {
        user: userSlice,
        locationSlice: locationsSlice,
        routeSlice: routeCreationSlice,
    },
});
