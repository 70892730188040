import React, {useEffect, useState} from 'react';

import RouteInfoList from "../../../../components/route_info_list/RouteInfoList";
import { Col, Row, Tabs,} from "antd";
import {
    LoadingOutlined,
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux'
import { fetchRoutes, deleteRoute, selectRoute, setEditMode, setRouteId, updateRoute } from "../../../../redux/RouteCreationSlice"
import LocationMapInfoSimple from "../../../../components/modal_location_info/LocationMapInfoSimple";
import LocationInfoModal from "../../../../components/list_items/location_info_card/LocationInfoModal";
import RouteEditMap from "../geo_reference_creation/RouteEditMap";
import {fetchLocations, selectLocation, editLocation, updateLocation} from "../../../../redux/locationsSlice";


const { TabPane } = Tabs;

export default function RoutsOverView (props) {
    const dispatch = useDispatch()

    const user = useSelector(state => state.user.user)
    const location_ = useSelector(state => state.locationSlice.location)
    const routes = useSelector(state => state.routeSlice.routes)
    const isLoading = useSelector(state => state.routeSlice.isLoading)
    const selectedRouteId = useSelector(state => state.routeSlice.selectedRouteId)

    useEffect(()=>{
        dispatch(fetchLocations(user))
        dispatch(fetchRoutes(user))
        dispatch(selectLocation())
    },[])

    function onSelectRoute(id) {
        dispatch(selectRoute({routeId: id, user: user}))
    }

    function onDeleteRoute(route) {
        dispatch(deleteRoute({route: route, user: user}))
    }

    function onChangeState({id, enabled}) {

        const route = routes.find(e => e.id === id)

        if (typeof route === 'undefined') {
            return
        }

        const tmpRoute = {...route, isActive: enabled}

        dispatch(updateRoute({route: tmpRoute, user: user}))
    }

    function onEditRoute(id) {
        if (typeof id === 'undefined' || id === null) {
            dispatch(setEditMode(false))
        }

        dispatch(setRouteId(id))

        if (typeof props.onEditRoute !== 'undefined'){
            props.onEditRoute(id)
        }
    }

    return (
        <div>
            <Row >
                <Col sm={12} md={6}  lg={6} >

                    {/* Loading info */}
                    {isLoading &&
                        <div style={{display: "flex",
                            flexDirection: "column",
                            alignContent: "center"}}>
                            <p style={{fontSize: 30, textAlign: "center"}}>
                                Loading
                            </p>
                            <LoadingOutlined style={{fontSize: 50}}/>
                        </div>
                    }

                    {/* No routs info */}
                    {(routes.length <= 0) &&
                        <div style={{display: "flex",
                            flexDirection: "column",
                            alignContent: "center"}}>
                            <p style={{fontSize: 30, textAlign: "center"}}>
                                No routs
                            </p>
                        </div>
                    }

                    {/* Rout list */}
                    <div style={{overflow: 'auto', height: '88vh', marginRight: 12}}>
                        <RouteInfoList
                            routes={routes}
                            selectedRouteId={selectedRouteId}
                            onSelectRoute={(id)=>onSelectRoute(id)}
                            onDeleteRoute={(id)=>onDeleteRoute(id)}
                            onEditRoute={(id)=>onEditRoute(id)}
                            onChange={(data)=>onChangeState(data)}
                        />
                    </div>
                </Col>

                <Col sm={12} md={18} lg={18}>

                    {/* Location information window */}
                    { location_ &&
                        <div style={{position: "absolute",
                            left: '50%', transform: 'translate(-50%)',
                            bottom: '2vh', zIndex: 1000}}>
                            <LocationMapInfoSimple
                                location={location_}
                            />
                        </div>
                    }


                    {/* Map with markers and paths */}
                    {/*<RoutsOverViewMap style={{height: '88vh'}}/>*/}
                    <RouteEditMap style={{height: '88vh'}}/>
                </Col>
            </Row>
        </div>
    )
}
