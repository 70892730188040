import React, {useEffect, useState} from 'react';
import { Layout, Menu } from 'antd';
import UserInformationView from "../settings/user_information/UserInformationView";

import {
    SettingOutlined, QrcodeOutlined, GlobalOutlined, EnvironmentOutlined, NodeIndexOutlined
} from '@ant-design/icons';
import RouteEditView from "../routs/geo_reference_creation/RouteEditView";
import RoutsOverView from "../routs/routs_overview/RoutsOverView";

import {useDispatch, useSelector} from "react-redux";
import LocationOverView from "../locations/locations_overview/LocationOverView";
import {useNavigate, useLocation} from "react-router-dom";

import {loginUser} from "../../../redux/UserSlice"
import AppHeader from "../../../components/app_header/AppHeader";

// const TEST_USER = 'test-user-0'
const TEST_USER = 'admin'

const LOCATION = "/user"
const LOCATION_USER = LOCATION + "/user_settings"
const LOCATION_ROUT_EDITOR = LOCATION + "/route_editor"
const LOCATION_ROUTS = LOCATION + "/routs"
const LOCATION_LOCATIONS = LOCATION + "/locations"

const MENU_SETTINGS = 'settings'
const MENU_ROUTE_MANAGER = 'route_manager'
const MENU_LOCATION_MANAGER = 'location_manager'


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

export default function UserArea (props) {
    const [menuIsCollapsed, toggleMenu] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState(LOCATION_LOCATIONS)
    const [routeId, setRouteId] = useState(undefined)

    let location = useLocation();
    let history = useNavigate();
    const user = useSelector(state => state.user.user)

    const dispatch = useDispatch()

    useEffect(() => {
        if (typeof user === 'undefined' || user === null) {
            dispatch(loginUser(TEST_USER))
        }
    }, [user])

    useEffect(() => {
        if (location.pathname === LOCATION || location.pathname === LOCATION + "/") {
            history(LOCATION_LOCATIONS);
            setRouteId(undefined)

            return
        }

        setSelectedMenu(location.pathname)
    }, [location])

    /**
     * Return the View that should be displayed.
     * @param {string} selectedMenu 
     * @returns 
     */
    const getSelectedView = (selectedMenu_) => {

        switch(selectedMenu_){
            case LOCATION_USER:
                return <UserInformationView />

            case LOCATION_ROUT_EDITOR:
                return <RouteEditView routeId={routeId}
                        type={"create"}
                />

            case LOCATION_ROUTS:
                return <RoutsOverView onEditRoute={(id)=>onEditRoute(id)}/>

            case LOCATION_LOCATIONS:
                return <LocationOverView />

            default:
                return <LocationOverView />
        }
    }

    const onClickMenu = (menu) => {
        history(menu.key);
        setRouteId(undefined)
    }

    function onEditRoute(id) {
        setRouteId(id)
        setSelectedMenu(LOCATION_ROUT_EDITOR)
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>

            {/* Header */}
            <Header className="site-layout-backgroundlogin" style={{ padding: 0 }} >
                <AppHeader menus={['logout']}/>
            </Header>

            {/* Menu */}
            <Layout >
                <Sider collapsible
                       collapsed={menuIsCollapsed}
                       onCollapse={()=>toggleMenu(!menuIsCollapsed)}
                >
                    <Menu theme="dark"
                          defaultSelectedKeys={[LOCATION_LOCATIONS]}
                          selectedKeys={[selectedMenu]}
                          defaultOpenKeys={[MENU_SETTINGS, MENU_ROUTE_MANAGER, MENU_LOCATION_MANAGER]}
                          mode="inline"
                          onClick={(e)=>onClickMenu(e)}
                    >
                        <SubMenu key={MENU_LOCATION_MANAGER} icon={<EnvironmentOutlined  />}
                                 title="Location Manager">
                            <Menu.Item key={LOCATION_LOCATIONS}>Overview</Menu.Item>
                        </SubMenu>

                        <SubMenu key={MENU_ROUTE_MANAGER} icon={<NodeIndexOutlined  />}
                                 title="Route Manager">
                            <Menu.Item key={LOCATION_ROUT_EDITOR}>Editor</Menu.Item>
                            <Menu.Item key={LOCATION_ROUTS}>Routs</Menu.Item>
                        </SubMenu>

                        <SubMenu key={MENU_SETTINGS} icon={<SettingOutlined />}
                                 title="Settings">
                            <Menu.Item key={LOCATION_USER}>User</Menu.Item>
                        </SubMenu>

                    </Menu>
                </Sider>


                {/* Main View */}
                <Layout className="site-layout">
                    <Content >
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            {getSelectedView(selectedMenu)}
                        </div>
                    </Content>
                </Layout>
            </Layout>

        </Layout>
    )
}
