import React, { useRef, useState, useEffect  } from "react";
import L from "leaflet";
import {v4 as uuidv4} from 'uuid';
import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import 'leaflet/dist/leaflet.css';

import { useDispatch, useSelector } from 'react-redux'
import { deleteLocation, editLocation, selectLocation, updateLocation, createLocation} from '../../../../redux/locationsSlice'
import { deleteLocationFromRoute_, addLocationToRoute, addLocationToRoute_} from '../../../../redux/RouteCreationSlice'


const markerStart = new L.icon({
    iconUrl: "../flag-icon-start.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const markerFinish = new L.icon({
    iconUrl: "../flag-icon-finish.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const markerStandard = new L.icon({
    iconUrl: "../marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const markerSelected = new L.icon({
    iconUrl: "../marker-icon-selected.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const markerInactive = new L.icon({
    iconUrl: "../marker-icon-inactive.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const markerInactiveSelected = new L.icon({
    iconUrl: "../marker-icon-incative-selected.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    popupAnchor:  [0, -38],
});

const mapCenter = [51.96253958406366, 7.62562943736918]
let llMap = null
let llPath = null
let selectedIdIntern = null

export default function RouteEditMap (props) {
    const mapDivEl = useRef(null);

    const [isInitialized, setIsInitialized] = useState(false)
    const [isEditMode, setIsEditMode] = useState(true)
    const [routeLocations, setRoutLocations] = useState([])

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user)
    const locations = useSelector(state => state.locationSlice.locations)
    const route = useSelector(state => state.routeSlice.route)
    const routeLocationIds = useSelector(state => state.routeSlice.routeLocations)
    const selectedLocationId = useSelector(state => state.locationSlice.selectedLocationId)


    // Add Markers to the map and fit the view
    function addMarkersToMap(locations) {
        if (llMap === null || typeof llMap === 'undefined') {
            return
        }

        const latLngs = locations.map(location=>{
            const latlng = L.latLng(location.latlng)
            addNewMarker(latlng, location.id)

            return latlng
        })

        // Fit the map view
        if ((llMap !== null && latLngs.length > 0) && isInitialized === false) {
            llMap.fitBounds(latLngs, {padding: [25, 25]})
        }

        setStartAndFinishIcons()

        // Draw the path
        redrawRoute()
    }

    function markerMoved(marker) {
        const locationId = getLocationId(marker)

        if (locationId=== null)
            return

        let location = locations.find(e=>e.id === locationId)
        dispatch(updateLocation({user: user, data: {...location, latlng: {
                    lat: marker.getLatLng().lat,
                    lng: marker.getLatLng().lng
                }
            }}))
    }

    function deselectAllMarkers() {
        if (llMap === null || typeof llMap === 'undefined') {
            return
        }

        // Reset all markers
        llMap.eachLayer(function(layer){
            if (typeof layer._latlng === 'undefined') {
                return
            }

            const isActive = layer.options.isActive

            layer.dragging.disable()
            layer.setIcon(isActive ? markerStandard : markerInactive)
        });

        selectedIdIntern = null
    }

    function setStartAndFinishIcons() {
        if (llMap === null || typeof llMap === 'undefined') {
            return
        }

        if (routeLocations.length <= 1) {
            return
        }

        // Set START and FINISH icon
        llMap.eachLayer(function(layer){        //iterate over map rather than clusters

            if (typeof layer._latlng === 'undefined') {
                return
            }

            if (typeof selectedLocationId !== 'undefined'
                && selectedLocationId === layer.options.locationId) {
                return
            }

            if (layer.options.locationId === routeLocations[0].id) {
                layer.setIcon(markerStart)
            }

            if (layer.options.locationId === routeLocations[routeLocations.length - 1].id) {
                layer.setIcon(markerFinish)
            }
        });
    }

    function getLocationId(marker){
        let locationId = marker.options.locationId

        if (locationId !== null) {
            return locationId
        }

        locationId = locations.find(location=>{
            if (marker.getLatLng().lat === location.lat && marker.getLatLng().lng === location.lng) {
                return true
            }

            return false
        })

        // If the marker was moved it can't be found in the old position data
        if (typeof locationId === 'undefined'  && selectedIdIntern !== null) {
            return selectedIdIntern
        }

        return (typeof locationId === 'undefined') ? null : locationId
    }

    function onClickMarker(marker) {
        const locationId = marker.options.locationId
        dispatch(selectLocation(locationId))
    }

    function selectMarker() {

        if (llMap === null || typeof llMap === 'undefined') {
            return
        }

        llMap.eachLayer(function(layer){        //iterate over map rather than clusters

            if (typeof layer._latlng === 'undefined') {
                return
            }

            if (typeof selectedLocationId !== 'undefined'
                && layer.options.locationId === selectedLocationId) {
                layer.setIcon(layer.options.isActive ? markerSelected : markerInactiveSelected)

                if (layer.options.isActive
                    && isEditMode === true) {
                    layer.dragging.enable()
                }
            }
        });
    }

    function onClickAddNewMarker (latlng) {
        dispatch(createLocation({user: user, latlng: { lat: latlng.lat, lng: latlng.lng}}))
    }

    // Add Marker to the map
    function addNewMarker(latlng, id=null) {
        if (llMap !== null) {

            const contextmenuItems = []
            const location = locations.find(loc=>loc.id === id)
            const routeLocation = routeLocations.find(e=>e.id === id)

            if (location.isActive === true) {
                contextmenuItems.push({
                    text: 'Delete location',
                    index: 3,
                    callback: deleteMarker
                })

                contextmenuItems.push({
                    text: 'Add to route',
                    index: 0,
                    callback: addExistingMarkerToRoute
                })
            }

            if (typeof routeLocation !== 'undefined') {

                contextmenuItems.push({
                    text: 'Remove from route',
                    index: 0,
                    callback: deleteMarkerFromRoute
                })
            }

            let marker = L.marker(latlng, {
                contextmenu: true,
                locationId: id === null ? uuidv4() : id,
                contextmenuItems: isEditMode ? contextmenuItems : undefined,
                isActive: location.isActive,
                icon: location.isActive ? markerStandard : markerInactive,
                draggable: false,
                autoPan: true
            }).addTo(llMap);

            marker.on('click', function (e) {
                onClickMarker(marker)
            })

            if (isEditMode) {
                marker.on('moveend', function () {
                    markerMoved(marker)
                })
            }
        }
    }

    function addExistingMarkerToRoute(e) {
        const location = locations.find(loc=>loc.id === e.relatedTarget.options.locationId)

        dispatch(addLocationToRoute({user: user, route: route,
            location: location}))
    }

    // Delete Marker from the map
    function deleteMarker(e) {
        // delete the marker from the map
        llMap.removeLayer(e.relatedTarget)

        const location = locations.find(loc=>loc.id === e.relatedTarget.options.locationId)

        dispatch(deleteLocation({user: user, location: location}))

        // redraw the path
        redrawRoute()
    }

    // Delete Marker from the map
    function deleteMarkerFromRoute(e) {
        // delete the marker from the map
        // llMap.removeLayer(e.relatedTarget)

        const location = locations.find(loc=>loc.id === e.relatedTarget.options.locationId)

        dispatch(deleteLocationFromRoute_({user: user, route: route, location: location}))

        // redraw the path
        redrawRoute()
    }

    function redrawRoute() {
        if (llMap === null
            || routeLocations.length <= 1) {
            return
        }

        const coordinates = []
        routeLocations.forEach(location=>{
            if (typeof location === 'undefined') {
                return
            }

            coordinates.push(location.latlng)
        })

        // delete the old path from the map
        if (llPath !== null) {
            llMap.removeLayer(llPath)
        }

        // Connect markers
        llPath = L.polyline(coordinates, {color: 'red'}).addTo(llMap);
    }

    function deleteAllMarkers() {
        if (llMap === null || typeof llMap === 'undefined') {
            return
        }

        // Reset all markers
        llMap.eachLayer(function(layer){

            if (typeof layer._latlng === 'undefined') {
                return
            }

            // delete the marker from the map
            llMap.removeLayer(layer)
        });

        // delete path
        if (llMap !== null && llPath !== null) {
            llMap.removeLayer(llPath)
        }
    }

    // Select marker
    useEffect(() => {
        const locs = []

        if (typeof routeLocationIds !== 'undefined') {
            routeLocationIds.forEach(locationId => {
                const loc = locations.find((location) => {
                    return location.id === locationId
                })

                if (typeof loc === 'undefined') {
                    return
                }

                locs.push(loc)
            })
        }

        setRoutLocations(locs)
    }, [locations, routeLocationIds])


    // Select marker
    useEffect(() => {
        deleteAllMarkers()

        // Add markers to the map after the map was created
        addMarkersToMap(locations)

        setStartAndFinishIcons()
        selectMarker()
    }, [routeLocations])

    useEffect(()=>{
        deselectAllMarkers()
        setStartAndFinishIcons()
        selectMarker()
    }, [selectedLocationId])


    // Initialize the map and markers at the beginning
    useEffect(() => {
        const {isEditMode} = props

        llMap = L.DomUtil.get('llMap');

        if(llMap !== null){
            llMap._leaflet_id = null;
            llMap = null
        }

        if (llMap === null) {
            // Add LeafLet map after initialisation
            llMap = L.map(mapDivEl.current, {
                center: (typeof props.center === 'undefined') ? mapCenter : props.center,
                zoom: (typeof props.zoom === 'undefined') ? 12 : props.zoom,
                contextmenu: true,
                contextmenuWidth: 140,
                contextmenuItems: isEditMode ? [{
                    text: 'Add new Point',
                    callback: (e)=>onClickAddNewMarker(e.latlng)
                }] : [],
                layers: [
                    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    }),
                ]
            });
        }

        // Add markers to the map after the map was created
        addMarkersToMap(locations)

        // Select marker
        if (typeof selectedIdIntern !== 'undefined' && selectedIdIntern !== null) {
            selectMarker()
        }

        setIsInitialized(true)

        if (typeof route !== 'undefined') {
            const locs = locations.filter(location => route.locationIds.includes(location.id))
            setRoutLocations(locs)
        }

        setIsEditMode(isEditMode)
    }, []); // <-- empty array means 'run once'

    return(
        <div style={{marginLeft: 16}}>
            <div id="llMap"
                 ref={mapDivEl}
                 style={typeof props.style === 'undefined' ? { height: 800 } : props.style}>
            </div>
        </div>
    )
}
