import React from "react";
import AppHeader from "../../../components/app_header/AppHeader";


export default function WelcomeView () {

    return (
        <div style={{backgroundColor: '#4fb4cb', color: '#ffffff', height: '98vh'}}>

            <AppHeader menus={['login', 'signin', 'user']}/>

            <h1>Welcome to the G A M E</h1>
        </div>
    )
}
