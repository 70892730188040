import React, {useEffect, useState} from 'react';
import {Button, Col, Input, message, Row,} from 'antd';
import { useNavigate } from "react-router-dom";

import AppHeader from "../../../components/app_header/AppHeader";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../redux/UserSlice";



export default function UserLogInView(props) {
    const [redirectTo, setRedirectTo] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const user = useSelector(state => state.user.user)

    const dispatch = useDispatch()

    useEffect(()=>{
        if (typeof user !== 'undefined' && user.id === email) {
            navigate('/user')
        }
    }, [user])

    async function onClickLogIn () {

        if (email.length === 0 || password.length === 0) {
            message.error("Please insert your email and password")
            return
        }

        if (typeof user !== 'undefined' && user.id === email) {
            navigate('/user')
            return
        }

        dispatch(loginUser(email))
    }


    return (
        <div>
            <AppHeader menus={['signin']}/>

            <div style={{margin: '120px 30px 0px 30px'}}>
                <Row>
                    <Col sm={{span: 20, offset: 1}} md={{span: 12, offset: 6}} lg={{span: 6, offset: 9}}>
                        <div style={{padding: '0px 20px 20px 20px', backgroundColor: '#f2f2f2'}}>
                            <h1 style={{textAlign: 'center'}}>Log In</h1>

                            <div>
                                <p style={{fontWeight: 'bold'}}>User Name or Email Address</p>
                                <Input placeholder="Email Address"
                                       onChange={(e) => setEmail(e.target.value)}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               onClickLogIn()
                                           }
                                       }}/>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{fontWeight: 'bold'}}>Password</p>
                                <Input.Password placeholder="Password" password
                                                onChange={(e) => setPassword(e.target.value)}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        onClickLogIn()
                                                    }
                                                }}/>
                            </div>

                            <Button type="primary" block
                                    style={{marginTop: 20}}
                                    onClick={() => onClickLogIn()}>
                                Log In
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
