import React, {Component, useEffect, useState} from "react";
import { Avatar, Image, Card, Modal, Button, Space } from 'antd';
import moment from "moment";

import {
    CheckCircleOutlined,
    DeleteOutlined,
    EnvironmentOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    EditOutlined
} from '@ant-design/icons';


const {Meta} = Card;

const COLOR_SELECTED = '#e8f5ff'

const defaultRoute = {id: null, name: "", description: ""}

export default function RouteInfoCard(props) {
    const [route, setRoute] = useState(defaultRoute);
    const [imageSrc, setImageSrc] = useState(undefined)
    const [isEnabled, setIsEnabled] = useState(true);
    const [isSelected, setIsSelected] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState('')
    const [created, setCreated] = useState('')
    const [modified, setModified] = useState('')
    const [points, setPoints] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        setRoute(props.route)

        if (typeof props.route === 'undefined' || props.route === null) {
            setIsEnabled(true)
            return
        }

        if (typeof props.route.name !== 'undefined') {
            setName(props.route.name)
        }

        if (typeof props.route.description !== 'undefined') {
            setDescription(props.route.description)
        }

        if (typeof props.route.src !== 'undefined' && props.route.src.length >=1) {
            setImageSrc(props.route.src)
        }

        if (typeof props.route.created !== 'undefined') {
            setCreated(moment(props.route.created).format('D MMM YYYY'))
        }

        if (typeof props.route.modified !== 'undefined') {
            setModified(moment(props.route.modified).format('D MMM YYYY'))
        }

        if (typeof props.route.locationIds !== 'undefined') {
            setPoints(props.route.locationIds.length)
        }

        setIsEnabled(props.route.isActive)
    }, [props.route])

    useEffect(() => {
        if (typeof props.isSelected === 'undefined' || props.isSelected === null)
            return

        if (props.isSelected !== isSelected) {
            setIsSelected(props.isSelected)
        }

    }, [props.isSelected])


    function showConfirm() {
        Modal.confirm({
            title: 'Do you Want to delete these route?',
            icon: <ExclamationCircleOutlined/>,
            content: 'Some descriptions',
            onOk() {
                console.log('OK');

                if (typeof props.onDelete !== 'undefined') {
                    props.onDelete(route)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function onChangeState(state) {
        setIsEnabled(!isEnabled)

        if (typeof props.onChangeState === 'undefined' || props.onChangeState === null) {
            return
        }

        props.onChangeState(route.id, state)
    }


    function onClickEnable(action) {
        setIsEnabled(action)

        if (typeof props.onChangeState === 'undefined' || props.onChangeState === null) {
            return
        }

        props.onChangeState(route.id, action)
    }

    function onClickEdit(action) {
        if (typeof props.onEdit === 'undefined' || props.onEdit === null) {
            return
        }

        setIsSelected(true)

        props.onEdit(route.id)
    }

    function onClickDelete(action) {
        if (typeof props.onDelete === 'undefined' || props.onDelete === null) {
            return
        }

        showConfirm()
    }

    function onClickCard() {

        if (typeof props.onClick === 'undefined' || typeof route.id === 'undefined') {
            return
        }

        props.onClick(isSelected ? null : route.id)
        setIsSelected(!isSelected)
    }

    function getAvatar() {
        if (typeof route === 'undefined' || route.src === '') {
            return <Avatar size={64} icon={<EnvironmentOutlined/>}/>
        }

        return <Avatar size={64}
                       src={<Image src={route.src}/>}
        />
    }

        return (
            <div>
                <Card style={isSelected ? {
                    marginTop: 16,
                    backgroundColor: COLOR_SELECTED,
                    } : {marginTop: 16,}}
                      hoverable

                      actions={[
                          <span>
                              <div onClick={() => onClickEdit()}><EditOutlined key="edit"/> Edit
                              </div>
                          </span>,
                          <span>
                          {isEnabled ? (
                                  <div onClick={() => onChangeState(false)}>
                                      <StopOutlined key="disable"/> Disable
                                  </div>

                          ) : (
                              <div onClick={() => onChangeState(true)}>
                                  <CheckCircleOutlined
                                      key="enable"/> Enable</div>
                          )
                          }
                          </span>,
                          <span>
                              <div onClick={() => onClickDelete()}><DeleteOutlined key="delete"/>
                                  Delete
                              </div>
                          </span>,
                      ]}
                >

                    {/* Content */}
                    <div style={{margin: -24, padding: 24}}
                         onClick={()=>onClickCard()}
                    >
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row", }}>
                                <div>
                                    {getAvatar()}
                                </div>

                                <div style={{marginLeft: 15}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>

                                        {/* Name */}
                                        <div style={{color: isEnabled ? "#389e0d" : "#cf1322",
                                            fontSize: 18, fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                            {name}
                                        </div>

                                        {/* Information */}
                                        <div style={{marginLeft: 7, fontSize: 12, color: '#8c8c8c', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                            <div style={{display: "flex", flexDirection: "row", }}>
                                                <div style={{display: "flex", flexDirection: "column", marginRight: 7}}>
                                                    <div>Created:</div>
                                                    <div>Modified:</div>
                                                    <div>Points:</div>
                                                </div>

                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <div>{created}</div>
                                                    <div>{modified}</div>
                                                    <div>{points}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: 10, marginLeft: 55,}}>
                            {description}
                        </div>
                    </div>

                </Card>

            </div>
        )
}
