import {v4 as uuidv4} from 'uuid';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import moment from "moment";
import {FirebaseController, GeoLocation, Mission, Rank, Role, User, UserRank, UserRole, Task} from "schnitzel-mc";


const emulatorSettings = {
    host: "localhost",
    port: 8080,
    authPort: 9099
}

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYYmIeYpFfcp2Ogkgjv7AGEwg_jFCmkYo",
    authDomain: "geolocations-01.firebaseapp.com",
    databaseURL: "https://geolocations-01-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "geolocations-01",
    storageBucket: "geolocations-01.appspot.com",
    messagingSenderId: "1091545108183",
    appId: "1:1091545108183:web:cec6e6ae5aea2e2e5caa92",
    measurementId: "G-91BMVH92TP"
};

const dbController = new FirebaseController(firebaseConfig)

const fetchLocations = createAsyncThunk('locations/fetchLocations',
    async (user) => {

        if (typeof user === 'undefined' || user.id === "") {
            return []
        }

        const tmpUser = new User((user),
            new UserRole(user.role, new Role(user.role.role)),
            new UserRank(user.rank, new Rank(user.rank.rank)))

        const objects = []
        await dbController.getLocations(tmpUser)
            .then(els => {
                els.forEach(e => {
                    objects.push(e.toJson())
                })
            })

        return objects
    })

const createLocation = createAsyncThunk('locations/createLocation',
    async ({user, latlng}) => {

        if (typeof user === 'undefined' || user.id === "") {
            return []
        }

        const location = new GeoLocation({
            name: `New location`,
            description: `New location`
        }, new User(user), latlng, new Mission({}, Task.LOCATION))

        await dbController.addLocation(location)

        return {
            location: location.toJson()
        }
    })

const updateLocation = createAsyncThunk('locations/updateLocation',
    async ({user, data}) => {

        let tmpUser = user

        if (user.role.role.id === Role.ADMIN.id) {
            tmpUser = await dbController.getUser(data.ownerId)
        }

        const location = new GeoLocation(data, tmpUser)

        await dbController.updateLocation(location)

        return {
            location: location.toJson()
        }
    })

const deleteLocation = createAsyncThunk('locations/deleteLocation',
    async ({user, location}) => {

        const tmpLocation = new GeoLocation(location, user)



        await dbController.removeLocation(tmpLocation, user)

        return {
            location: tmpLocation.toJson()
        }
    })

const initialState = {

    selectedLocationId: undefined,
    edit: false,

    location: undefined,
    locations: []
}

const user = new User({name: "Test User"})

function getInitialState() {

    return {
        ...initialState
    }
}

export const routeSlice = createSlice({
    name: 'locations',
    initialState: getInitialState(),

    reducers: {
        editLocation: (state, action) => {
            state.edit = action.payload
        },
        selectLocation: (state, action) => {
            if (typeof action.payload === 'undefined'
                || action.payload === null
                || action.payload === state.selectedLocationId) {
                state.location = undefined
                state.selectedLocationId = undefined
                return
            }

            const location = state.locations.find(location=>location.id === action.payload)
            if (typeof location === 'undefined') {
                return;
            }

            state.location = location
            state.selectedLocationId = location.id
        },
    },

    extraReducers(builder) {
        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            const data = action.payload
            state.locations = data
            state.location = undefined
            state.selectedLocationId = undefined
        })

        builder.addCase(createLocation.fulfilled, (state, action) => {
            const {location} = action.payload

            if (typeof location === 'undefined') {
                return
            }

            state.locations.push(location)
        })

        builder.addCase(updateLocation.fulfilled, (state, action) => {
            const {location} = action.payload

            if (typeof location === 'undefined') {
                return
            }

            const index = state.locations.findIndex(loc=>loc.id === location.id)
            if (index < 0) {
                return;
            }

            const latlng = typeof location.latlng === 'undefined' ? {
                lat: location.lat, lng: location.lng} : location.latlng

            const tempLoc = new GeoLocation(location, state.user, latlng)

            state.locations[index] = tempLoc.toJson()

            // Update information
            if (state.selectedLocationId === tempLoc.id) {
                state.location = tempLoc.toJson()
            }
        })

        builder.addCase(deleteLocation.fulfilled, (state, action) => {
            const {location} = action.payload

            if (typeof location === 'undefined') {
                return
            }

            if (location.id === state.selectedLocationId) {
                state.location = undefined
                state.selectedLocationId = undefined
            }

            state.locations = state.locations.filter(e=>e.id !== location.id)
        })
    }
})

// Action creators are generated for each case reducer function
export const {
    editLocation,
    selectLocation,
} = routeSlice.actions

export {fetchLocations, createLocation, updateLocation, deleteLocation}

export default routeSlice.reducer