import React, {Component, useEffect, useState} from "react";
import { Form, Input, InputNumber, Button, Upload, message, } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import {updateUser} from '../../../../redux/UserSlice'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImageUploader from "../../../../components/image_uploader/ImageUploader";

import {
    StarOutlined
} from '@ant-design/icons';
import moment from "moment/moment";


export default function UserInformationView (props) {
    const [imageSrc, setImageSrc] = useState('')
    const [userName, setUserName] = useState('')


    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()

    useEffect(()=>{

        if (typeof user === 'undefined' || user === null) {
            setUserName("")
            setImageSrc("")
            return
        }

        setUserName(user.name)
        setImageSrc(user.src)
    }, [user])

    function onChangeUserName(e) {
        setUserName(e.target.value)
    }

    function onChangeUserImage(src) {
        setImageSrc(src)
    }

    function onClickSaveChanges() {
        const tmpUser = {...user, name: userName, src: imageSrc}
        dispatch(updateUser(tmpUser))
    }

    function getLevelStars(lv) {
        const stars = []

        for (let i=0; i < parseInt(lv.replace('lv', '')); i++) {
            stars.push(<StarOutlined key={'star_' + i} />)
        }

        return stars
    }

    return (
            <div>

                <div style={{display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: '50%'
                }}>
                    {user &&
                        <div>
                            <div style={{marginBottom: 20}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>Name</div>
                                <Input defaultValue={user.name}
                                       onChange={(e)=>onChangeUserName(e)}/>
                            </div>

                            <div style={{marginBottom: 20}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>Created</div>
                                <p>{moment(user.created).format('D MMM YYYY')}</p>
                            </div>

                            <div style={{marginBottom: 20}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>Modified</div>
                                <p>{moment(user.modified).format('D MMM YYYY')}</p>
                            </div>


                            <div style={{marginBottom: 20}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>Rank</div>
                                <div style={{color: "#faad14", fontSize: 23}}>{getLevelStars(user.rank.rank)}</div>
                            </div>

                            <div style={{marginBottom: 20}}>
                                <div style={{fontSize: 20, fontWeight: 'bold'}}>Image</div>
                                <ImageUploader src={imageSrc}
                                               onChange={(src)=>onChangeUserImage(src)}/>
                            </div>

                            <div style={{display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: '100%',
                                marginTop: 70
                            }}>
                                <Button type="primary" block
                                        onClick={()=>onClickSaveChanges()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    }



                </div>


            </div>
        )
}
