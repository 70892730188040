import React, {Component, useEffect, useState} from "react";
import {Avatar, Image, Card, Modal, Button, Space} from 'antd';
import { useDispatch, useSelector } from 'react-redux'

import LocationInfo from "./LocationInfo";


const {Meta} = Card;

const styleIsSelected = {
    webkitBoxShadow: '0px 1px 8px 2px #0087FF',
    boxShadow: '0px 1px 8px 2px #0087FF'
}


export default function LocationInfoModal(props) {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [location, setLocation] = useState(null);
    const [locationName, setLocationName] = useState('');
    const [locationDescription, setLocationDescription] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [imageIsLoading, setImageIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // const selectedLocationId = useSelector(state => state.map.selectedLocationId)
    // const dispatch = useDispatch()


    useEffect(() => {

        if (typeof props.location === 'undefined')
            return

        if (typeof props.location.name !== 'undefined')
            setLocationName(props.location.name)

        if (typeof props.location.description !== 'undefined')
            setLocationDescription(props.location.description)

        if (typeof props.location.mission.src !== 'undefined')
            setImageSrc(props.location.mission.src)

        setLocation(props.location)
    }, [])

    useEffect(() => {

        if (typeof props.location === 'undefined')
            return

        if (typeof props.location.name !== 'undefined')
            setLocationName(props.location.name)

        if (typeof props.location.description !== 'undefined')
            setLocationDescription(props.location.description)

        if (typeof props.location.mission.src !== 'undefined')
            setImageSrc(props.location.mission.src)

        setLocation(props.location)
    }, [props.location])

    useEffect(() => {

        if (typeof props.location === 'undefined')
            return

        setLocationName(props.location.name)
        setLocationDescription(props.location.description)
        setLocation(props.location)
    }, [props.location])

    useEffect(() => {

        if (typeof props.visible === 'undefined')
            return

        setConfirmLoading(false)
        setIsVisible(props.visible)

    }, [props.visible])

    function onClickCancel() {
        setIsVisible(false);

        // Reset the input
        setLocationName(location.name)
        setLocationDescription(location.description)

        if (typeof props.onCancel === 'function') {
            props.onCancel()
        }
    }

    function onClickOk() {
        setConfirmLoading(true)

        setIsVisible(!isVisible)
        setConfirmLoading(false);

        const mission = {...location.mission}
        mission.src = imageSrc

        const updatedLocation = Object.assign({...location}, {
            name: locationName,
            description: locationDescription,
            mission: mission
        })

        if (typeof props.onOk === 'function') {
            props.onOk(updatedLocation)
        }
    }

    function onChangeTitle(value) {
        setLocationName(value)
    }

    function onChangeDescription(value) {
        setLocationDescription(value)
    }

    function onChangeImage(value) {
        debugger
        setImageSrc(value)
    }

    return (
        <div>
            <Modal
                title="Location information"
                visible={isVisible}
                onOk={() => onClickOk()}
                confirmLoading={confirmLoading}
                onCancel={() => onClickCancel()}
                closable={false}
                afterClose={() => onClickCancel()}
            >

                <LocationInfo
                    location={{
                        name: locationName,
                        description: locationDescription,
                        imageSrc: imageSrc
                    }}
                    onChangeTitle={(value) => onChangeTitle(value)}
                    onChangeDescription={(value) => onChangeDescription(value)}
                    onChangeImage={(value) => onChangeImage(value)}
                />
            </Modal>
        </div>
    )
}
