import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {FirebaseController, User, GeoLocation, Route, UserRole, Role, UserRank, Rank} from "schnitzel-mc"
import {fetchLocations} from "./locationsSlice";

import AvatarCreator from './../common/AvatarCreator'


const SLICE_NAME = "routeCreation"

const emulatorSettings = {
    host: "localhost",
    port: 8080,
    authPort: 9099
}

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYYmIeYpFfcp2Ogkgjv7AGEwg_jFCmkYo",
    authDomain: "geolocations-01.firebaseapp.com",
    databaseURL: "https://geolocations-01-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "geolocations-01",
    storageBucket: "geolocations-01.appspot.com",
    messagingSenderId: "1091545108183",
    appId: "1:1091545108183:web:cec6e6ae5aea2e2e5caa92",
    measurementId: "G-91BMVH92TP"
};

const dbController = new FirebaseController(firebaseConfig)

const createRoute = createAsyncThunk('routs/createRoute',
    async ({user}) => {
        if (typeof user === undefined || user === null) {
            return
        }

        const route = new Route({name: "New Route"}, new User(user))
        route.src = AvatarCreator.createAvatar()
        await dbController.addRoute(route)

        return {
            route: route.toJson(),
            routeId: route.id,
            routeLocations: route.locationIds,
        }
    })

const fetchRoutes = createAsyncThunk('routs/fetchRoutes',
    async (user) => {

        if (typeof user === 'undefined' || user.id === "") {
            return []
        }

        const tmpUser = new User((user),
            new UserRole(user.role, new Role(user.role.role)),
            new UserRank(user.rank, new Rank(user.rank.rank)))

        const objects = []
        await dbController.getRoutes(tmpUser)
            .then(els => {
                els.forEach(e => {
                    objects.push(e.toJson())
                })
            })

        return {
            routes: objects
        }
    })

const selectRoute = createAsyncThunk('routs/selectRoute',
    async ({routeId, user}) => {
        if (typeof user === undefined || user === null) {
            return
        }

        if (typeof routeId === 'undefined' || routeId === null) {
            return {
                route: undefined,
                routeId: undefined,
                routeLocations: [],
            }
        }

        const tmpUser = new User((user),
            new UserRole(user.role, new Role(user.role.role)),
            new UserRank(user.rank, new Rank(user.rank.rank)))


        const route = await dbController.getRoute(routeId, tmpUser)

        return {
            route: route.toJson(),
            routeId: route.id,
            routeLocations: route.locationIds,
        }
    })

const updateRoute = createAsyncThunk('routs/updateRoute',
    async ({route, user}) => {
        let tmpUser = user

        if (user.role.role === Role.ADMIN.id) {
            tmpUser = await dbController.getUser(route.ownerId)
        }

        const tmpRoute = new Route((route), tmpUser)
        await dbController.updateRoute(tmpRoute, tmpUser)

        return tmpRoute.toJson()
    })

const saveNewRoute = createAsyncThunk('routs/saveNewRoute',
    async ({route, user}) => {

        const tmpRoute = new Route((route), user)
        await dbController.addRoute(tmpRoute)

        return tmpRoute.toJson()
    })

const deleteRoute = createAsyncThunk('routs/deleteRoute',
    async ({route, user}) => {

        const tmpRoute = new Route((route), user)

        await dbController.removeRoute(tmpRoute, user)

        return {
            route: tmpRoute.toJson()
        }
    })

const addLocationToRoute_ = createAsyncThunk('routs/addLocationToRoute',
    async ({user, route, location}, {getState}) => {

        if (typeof route === 'undefined') {
            return
        }

        const state = getState()[SLICE_NAME];



        const tmpLocation = new GeoLocation(location, user).toJson()
        const tmpRoute = new Route(route, user).toJson()

        const locationIds = [...tmpRoute.locationIds]
        const routeIds = [...tmpLocation.routeIds]

        locationIds.push(tmpLocation.id)
        routeIds.push(tmpRoute.id)

        tmpRoute.locationIds = locationIds
        tmpLocation.routeIds = routeIds

        if (typeof state.routes.find(e=>e.id === route.id) === 'undefined') {
            return tmpRoute
        }

        await dbController.updateRoute(new Route(tmpRoute), user)
        await dbController.updateLocation(new GeoLocation(tmpLocation), user)

        const updatedRoute = await dbController.getRoute(route.id, user)

        return updatedRoute.toJson()
    })

const deleteLocationFromRoute_ = createAsyncThunk('routs/deleteLocationFromRoute',
    async ({user, route, location}) => {

        if (typeof route === 'undefined') {
            return
        }

        const tmpLocation = new GeoLocation(location, user).toJson()
        const tempRoute = new Route(route, user).toJson()
        const locationIds = [...tempRoute.locationIds]
        const routeIds = [...tmpLocation.routeIds]

        const connectedLocationIds = locationIds.filter(id => id !== tmpLocation.id)
        const connectedRouteIds = routeIds.filter(id => id !== route.id)

        tempRoute.locationIds = connectedLocationIds
        tmpLocation.routeIds = connectedRouteIds

        await dbController.updateRoute(new Route(tempRoute), user)
        await dbController.updateLocation(new GeoLocation(tmpLocation), user)

        const updatedRoute = await dbController.getRoute(tempRoute.id, user)
        const updatedLocation = await dbController.getLocation(tmpLocation.id, user)

        return {
            route: updatedRoute.toJson(),
            location: updatedLocation.toJson()
        }
    })

const locationUp_ = createAsyncThunk('routs/locationUp_',
    async ({locationId, locationIds}) => {
        let locIndex = locationIds.findIndex(id=>id === locationId)

        if (locIndex <= 0)
            return locationIds

        const tmpIds = locationIds.toSpliced(locIndex - 1, 0, locationIds[locIndex])
        tmpIds.splice(locIndex + 1, 1)

        return tmpIds
    })

const locationDown_ = createAsyncThunk('routs/locationDown_',
    async ({locationId, locationIds}) => {
        let locIndex = locationIds.findIndex(id=>id === locationId)

        if (locIndex >= (locationIds.length - 1) || locIndex < 0)
            return locationIds

        const tmpIds = locationIds.toSpliced(locIndex + 2, 0, locationIds[locIndex])
        tmpIds.splice(locIndex, 1)

        return tmpIds
    })

const initialState = {
    selectedLocationId: undefined,
    edit: false,
    isLoading: false,

    location: undefined,
    locations: [],
    routeLocations: [],

    route: undefined,
    routeId: undefined,
    routes: [],
}

export const routeCreationSlice = createSlice({
    name: 'routeCreation',
    initialState: initialState,

    reducers: {
        setIsLoading: (state, action) => {
            if (typeof action.payload !== 'boolean'){
                return
            }

            state.edit = action.payload
        },

        setEditMode: (state, action) => {
            if (typeof action.payload !== 'boolean'){
                return
            }

            state.edit = action.payload
        },

        setRouteId: (state, action) => {
            if (typeof action.payload === 'undefined' || action.payload === null){
                state.edit = false
                state.route =  undefined
                state.routeId =  undefined
                state.routeLocations = []
                return
            }

            state.routeId = action.payload
        },

        creteNewRoute: (state, action) => {
            const emptyRoute = new Route({name: "New Route"}, state.user).toJson()

            state.route = emptyRoute
            state.routeId = emptyRoute.id
            state.routes = []

            state.selectedLocationId = undefined
            state.edit = false

            state.location = undefined
            state.locations = []
            state.routeLocations = []
        },

        deleteLocationFromRoute: (state, action) => {

            if (action.payload === state.selectedLocationId) {
                state.location = undefined
                state.selectedLocationId = undefined
            }

            state.routeLocations = state.routeLocations.filter(id=>id !== action.payload)
        },
        // updateLocation: (state, action) => {
        //     const index = state.locations.findIndex(location=>location.id === action.payload.id)
        //     if (index < 0) {
        //         return;
        //     }
        //
        //     const latlng = typeof action.payload.latlng === 'undefined' ? {
        //         lat: action.payload.lat, lng: action.payload.lng} : action.payload.latlng
        //
        //     const tempLoc = new GeoLocation(action.payload, state.user, latlng)
        //
        //     state.locations[index] = tempLoc.toJson()
        //     state.location = tempLoc.toJson()
        //     state.routeLocations = state.routeLocations.filter(id=>state.route.locationIds.includes(id))
        //
        //     state.routeLocations.forEach((id, i)=>{
        //         if (id === action.payload.id) {
        //             state.routeLocations[i] = tempLoc.id
        //         }
        //     })
        // },
        updateRouteLocalChanges: (state, action) => {
            const {route, user} = action.payload

            const tmpRoute = new Route(route).toJson()

            state.route = tmpRoute
        },
        addLocationToRoute: (state, action) => {
            const {location} = action.payload

            const route = state.route

            if (route.locationIds.some(id => id === location.id)) {
                return
            }

            state.route.locationIds.push(location.id)
            state.routeLocations.push(location.id)
        },
        locationUp: (state, action) => {
            const {locationId, locationIds} = action.payload

            let locIndex = locationIds.findIndex(id=>id === locationId)

            if (locIndex <= 0)
                return

            const tmpIds = locationIds.toSpliced(locIndex - 1, 0, locationIds[locIndex])
            tmpIds.splice(locIndex + 1, 1)

            state.routeLocations = tmpIds
            state.route.locationIds = tmpIds
        },
        locationDown: (state, action) => {
            const {locationId, locationIds} = action.payload

            let locIndex = locationIds.findIndex(id=>id === locationId)

            if (locIndex >= (locationIds.length - 1) || locIndex < 0)
                return locationIds

            const tmpIds = locationIds.toSpliced(locIndex + 2, 0, locationIds[locIndex])
            tmpIds.splice(locIndex, 1)

            state.routeLocations = tmpIds
            state.route.locationIds = tmpIds
        }
    },

    extraReducers(builder) {
        builder.addCase(fetchRoutes.pending, (state, action) => {
            console.log("Pending")
            state.isLoading = true
        })
        builder.addCase(fetchRoutes.rejected, (state, action) => {
            console.log("Rejected")
            state.isLoading = false
        })

        builder.addCase(fetchRoutes.fulfilled, (state, action) => {
            const {routes} = action.payload

            if (typeof routes === 'undefined') {
                return
            }

            state.routes = routes
            state.route =  undefined
            state.routeId =  undefined
            state.routeLocations = []
            state.isLoading = false
        })

        builder.addCase(createRoute.fulfilled, (state, action) => {
            const {route, routeId, routeLocations} = action.payload

            state.route = route
            state.routeId = routeId
            state.routeLocations = routeLocations

            state.routeIsLoaded = true
        })

        builder.addCase(selectRoute.fulfilled, (state, action) => {
            const {route, routeId, locations, routeLocations} = action.payload

            state.route = route
            state.routeId = routeId
            state.routeLocations = routeLocations

            state.routeIsLoaded = true
        })

        builder.addCase(selectRoute.rejected, (state, action) => {

            state.route = undefined
            state.routeId = undefined
            state.routeLocations = []

            state.routeIsLoaded = true
        })

        builder.addCase(updateRoute.fulfilled, (state, action) => {
            const selectedRoute = action.payload

            state.rotue = selectedRoute
            state.routeId = selectedRoute.id
            state.routeIsLoaded = true
        })

        builder.addCase(saveNewRoute.fulfilled, (state, action) => {
            const rotue = action.payload

            state.rotue = rotue
            state.routeId = rotue.id
            state.routeIsLoaded = true
        })

        builder.addCase(addLocationToRoute_.fulfilled, (state, action) => {
            const route = action.payload

            state.route = route
            state.routeId = route.id
            state.routeIsLoaded = true

            state.route.locationIds = route.locationIds
            state.routeLocations = route.locationIds
        })

        builder.addCase(deleteLocationFromRoute_.fulfilled, (state, action) => {
            const {route, location} = action.payload

            state.route = route
            state.routeId = route.id
            state.routeIsLoaded = true

            state.route.locationIds = route.locationIds
            state.routeLocations = route.locationIds
        })

        builder.addCase(locationUp_.fulfilled, (state, action) => {
            const locationIds = action.payload

            state.route.locationIds = locationIds
            state.routeLocations = locationIds
        })

        builder.addCase(locationDown_.fulfilled, (state, action) => {
            const locationIds = action.payload

            state.route.locationIds = locationIds
            state.routeLocations = locationIds
        })

        builder.addCase(deleteRoute.fulfilled, (state, action) => {
            const {route} = action.payload

            if (typeof route === 'undefined') {
                return
            }

            if (route.id === state.routeId) {
                state.edit = false
                state.route =  undefined
                state.routeId =  undefined
                state.routeLocations = []
            }

            state.routes = state.routes.filter(e=>e.id !== route.id)
        })
    }
})

// Action creators are generated for each case reducer function
export const {
    creteNewRoute,
    deleteLocationFromRoute,
    updateRouteLocalChanges,
    addLocationToRoute,
    locationUp,
    locationDown,
    setEditMode,
    setRouteId,
} = routeCreationSlice.actions

export {fetchRoutes,
    createRoute,
    selectRoute,
    deleteRoute,
    saveNewRoute,
    updateRoute,
    addLocationToRoute_,
    deleteLocationFromRoute_,
    locationUp_,
    locationDown_}

export default routeCreationSlice.reducer
