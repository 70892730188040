import React, {useEffect, useState} from "react";
import {Modal} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux'
import {
    editLocation,
    selectLocation,
    updateLocation,
    deleteLocation, fetchLocations,
} from '../../../../redux/locationsSlice';
import LocationMapInfoSimple from "../../../../components/modal_location_info/LocationMapInfoSimple";
import LocationInfoModal from "../../../../components/list_items/location_info_card/LocationInfoModal";
import LocationOverviewMap from "./LocationOverviewMap";

const { confirm } = Modal;

export default function LocationOverView (props) {
    const [isDeleteInfoOpen, setIsDeleteInfoOpen] = useState(false)

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user)
    const edit_ = useSelector(state => state.locationSlice.edit)
    const location_ = useSelector(state => state.locationSlice.location)
    const selectedLocationId = useSelector(state => state.locationSlice.selectedLocationId)
    const locations_ = useSelector(state => state.locationSlice.locations)

    useEffect(()=>{
        dispatch(fetchLocations(user))
        dispatch(selectLocation())
    },[user])


    function showLocationDeleteInfo() {
        // setIsDeleteInfoOpen(true)

        confirm({
            title: `Location: ${location_.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Do you Want to delete these location?',
            onOk() {
                onDelete()
            }
        });
    }

    function onChangeLocationInfo(info) {
        debugger
    }

    function onSelectLocation(id) {
        dispatch(selectLocation(id))
    }

    function onEdit() {
        dispatch(editLocation(!edit_))
    }

    function onEnable() {
        const locData =  {...location_, isActive: true}
        dispatch(updateLocation({user: user, data: locData}))
    }

    function onDisable() {
        const locData =  {...location_, isActive: false}
        dispatch(updateLocation({user: user, data: locData}))
    }

    function onDelete() {
        dispatch(deleteLocation({user: user, location: location_}))
    }

    function onClickCancelEdit() {
        dispatch(editLocation(false))
    }

    function onClickOkEdit(data) {
        dispatch(updateLocation({user: user, data: data}))
    }

    function onAddLocation(latlng) {
        // dispatch(createLocation_({ lat: latlng.lat, lng: latlng.lng}))
    }

    return (
        <div>
            {/* Location delete info */}
            {(typeof location_ !== 'undefined') &&
                <Modal title={`Location: ${location_.name}`}
                       visible={isDeleteInfoOpen}
                       onOk={()=>onDelete()}
                       onCancel={()=>setIsDeleteInfoOpen(false)}
                >
                    <p>Do you really want delete this location</p>
                </Modal>
            }

            {/* Location information window */}
            { location_ &&
                <div style={{position: "absolute",
                    left: '50%', transform: 'translate(-50%)',
                    bottom: 50, zIndex: 1000}}>
                    <LocationMapInfoSimple
                        location={location_}
                        onEdit={()=>onEdit()}
                        onEnable={()=>onEnable()}
                        onDisable={()=>onDisable()}
                        onDelete={()=>showLocationDeleteInfo()}
                    />
                </div>
            }

            {/* Location edit window */}
            {location_ &&
                <LocationInfoModal
                    location={location_}
                    visible={edit_}
                    onOk={(location) => onClickOkEdit(location)}
                    confirmLoading={true}
                    onCancel={() => onClickCancelEdit()}
                />
            }

            {/* Map with markers and paths */}
            <LocationOverviewMap style={{height: '88vh', width: '100%'}}/>
        </div>
    )
}
