import React, {useState, useEffect} from "react";
import LocationInfoCard from "../list_items/location_info_card/LocationInfoCard";

export default function GeoReferenceList(props) {
    const [selectedCard, setSelectedCard] = useState(null)
    const [locations, setLocations] = useState(null)
    const [selectedLocationId, setSelectedLocationId] = useState(null)

    useEffect(()=>{
        setSelectedLocationId(props.selectedLocationId)
    }, [props.selectedLocationId])

    useEffect(()=>{
        let tempLocations = []

        if (typeof props.locations !== 'undefined' && props.locations !== null) {
            tempLocations = [...props.locations]
        }

        setLocations(tempLocations)
    }, [props.locations])


    function onClickCard(index) {
        setSelectedCard(index !== selectedCard ? index : null)
        // dispatch(selectLocation({ selectedLocationId: index !== selectedCard ? index : null}))

        if (typeof props.onSelectItem !== 'undefined') {
            props.onSelectItem(index !== selectedCard ? index : undefined)
        }
    }

    function onDelete(key) {
        console.log("Delete clicked")
    }

    function onLocationUp(id) {
        if (typeof props.onLocationUp !== 'undefined') {
            props.onLocationUp(id)
        }
    }

    function onLocationDown(id) {
        if (typeof props.onLocationDown !== 'undefined') {
            props.onLocationDown(id)
        }
    }

    function onClickEdit() {

        if (typeof props.onEdit !== 'undefined') {
            props.onEdit()
        }
    }

    return (

        <div >
            {locations &&
                locations.map((e, i) => {
                    return (
                        <LocationInfoCard
                            key={'geoPointItem_' + i}
                            location={e}
                            isSelected={typeof selectedLocationId !== 'undefined' && selectedLocationId === e.id}
                            onClickUp={(id)=>onLocationUp(e.id)}
                            onClickDown={(id)=>onLocationDown(e.id)}
                            onClickEdit={()=>onClickEdit()}
                            onClick={(id)=>onClickCard(id)}
                            position={i+1}
                        />
                    )
                })
            }
        </div>
    )
}
