import React, {useEffect, useState} from "react";
import RouteInfoCard from "../geo_item_info_card/RouteInfoCard";

const FUNCTIONS = {
    onSelectRoute: 'onSelectRoute',
    onDeleteRoute: 'onDeleteRoute',
    onEditRoute: 'onEditRoute',
    onChange: 'onChange'
}

export default function RouteInfoList(props) {
    const [routes, setRoutes] = useState([])
    const [selectedRouteId, setSelectedRoutId] = useState(null)

    useEffect(()=>{
        setRoutes(props.routes)

        if (typeof props.selectedRouteId !== 'undefined') {
            setSelectedRoutId(props.selectedRouteId)
        }
    }, [props.routes])

    function callFunction(functionName, data) {
        if (typeof props[functionName] === 'undefined' || props[functionName] === null) {
            return
        }

        props[functionName](data)
    }

    function onClickRoute(id) {
        setSelectedRoutId(id)

        callFunction(FUNCTIONS.onSelectRoute, id)
    }

    function onClickDeleteRoute(id) {
        callFunction(FUNCTIONS.onDeleteRoute, id)
    }

    function onClickEditRoute(id) {
        callFunction(FUNCTIONS.onEditRoute, id)
    }

    function onRouteChangeState(id, isEnabled) {
        callFunction(FUNCTIONS.onChange, {id: id, enabled: isEnabled})
    }

    function isRouteSelected(id) {
        if (typeof id === 'undefined'
            || id === null
            || selectedRouteId === null) {
            return false
        }

        return id === selectedRouteId
    }

    return (
        <div>
            {routes &&
                routes.map((route, i)=>{
                    return <RouteInfoCard key={'route_info_' + i}
                                          route={route}
                                          isSelected={isRouteSelected(route.id)}
                                          onClick={(id)=>onClickRoute(id)}
                                          onDelete={(id=>onClickDeleteRoute(id))}
                                          onEdit={(id)=>onClickEditRoute(id)}
                                          onChangeState={(id, isEnabled)=>onRouteChangeState(id, isEnabled)}
                    />
                })
            }

        </div>
    )
}
