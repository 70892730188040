import React, {useEffect, useState} from "react";
import {Button, Checkbox, Image, Input, message, Upload} from "antd";
import {LoadingOutlined, PlusOutlined, InboxOutlined } from "@ant-design/icons";
import ImageUploader from "../image_uploader/ImageUploader";

const { Dragger } = Upload;


export default function RouteSettings(props){
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [imageSrc, setImageSrc] = useState('');


    useEffect(() =>{
        if (typeof props.route === 'undefined' || props.route === null) {
            setName('')
            setDescription('')
            setImageSrc('')

            return;
        }


        if (typeof props.route.name !== 'undefined')
            setName(props.route.name)

        if (typeof props.route.description !== 'undefined')
            setDescription(props.route.description)

        if (typeof props.route.src !== 'undefined')
            setImageSrc(props.route.src)

    }, [props.route])



    function onChangeTitle(v) {
        setName(v)

        onChangeSettings({
            name: v,
            description: description,
            src: imageSrc
        })
    }

    function onChangeDescription(v) {
        setDescription(v)

        onChangeSettings({
            name: name,
            description: v,
            src: imageSrc
        })
    }

    function onChangeImage(src) {
        onChangeSettings({
            name: name,
            description: description,
            src: src
        })
    }

    function onChangeSettings(settings) {

        if (typeof props.onChange === 'undefined')
            return

        props.onChange(settings)

    }

    return(
        <div>
            <div>
                <h2>Title</h2>
                <Input
                    value={name}
                    placeholder="Title"
                    style={{marginBottom: 20}}
                    onChange={(e) => onChangeTitle(e.currentTarget.value)}
                />
            </div>
            <div>
                <h2>Description</h2>
                <Input.TextArea
                    value={description}
                    onChange={(e) => onChangeDescription(e.currentTarget.value)}
                    placeholder="Description"
                    autoSize={{minRows: 3, maxRows: 5}}
                />
            </div>
            <div>
                <h2>Image</h2>
                <div>
                    <ImageUploader src={imageSrc}
                                   onChange={(src)=>onChangeImage(src)}/>
                </div>
            </div>
        </div>
    )
}
