import React, {useEffect, useState,} from "react";
import {Button, Col, Row, Tabs, Modal } from 'antd';
import GeoReferenceList from "./../../../../components/geo_reference_list/GeoReferenceList";

import RouteSettings from "../../../../components/route/RouteSettings";
import RouteEditMap from "./RouteEditMap";

import { useDispatch, useSelector } from 'react-redux'
import {
    deleteLocation,
    editLocation,
    fetchLocations,
    selectLocation,
    updateLocation
} from '../../../../redux/locationsSlice'
import {setEditMode, setRouteId, locationUp,
    locationDown, updateRoute, updateRouteLocalChanges,
    selectRoute, createRoute, saveNewRoute} from '../../../../redux/RouteCreationSlice'
import LocationMapInfoSimple from "./../../../../components/modal_location_info/LocationMapInfoSimple";
import LocationInfoModal from "./../../../../components/list_items/location_info_card/LocationInfoModal";
import RouteCreation from "../../../../components/route/RouteCreation";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {GeoLocation} from "schnitzel-mc";

const { TabPane } = Tabs;

export default function RouteEditView (props) {

    const [isRouteEditor, setIsRouteEditor] = useState(false)
    const [isRouteChanged, setIsRouteChanged] = useState(false)
    const [localRoute, setLocalRoute] = useState(undefined)

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user)
    const location_ = useSelector(state => state.locationSlice.location)
    const locations = useSelector(state => state.locationSlice.locations)
    const routeLocationIds = useSelector(state => state.routeSlice.routeLocations)
    const route_ = useSelector(state => state.routeSlice.route)
    const routes = useSelector(state => state.routeSlice.routes)
    const routeId = useSelector(state => state.routeSlice.routeId)
    const showLocationEditor = useSelector(state => state.locationSlice.edit)
    const showRouteEditor = useSelector(state => state.routeSlice.edit)
    const selectedLocationId = useSelector(state => state.locationSlice.selectedLocationId)

    useEffect(()=>{
        dispatch(fetchLocations(user))

        return () => {
            dispatch(setEditMode(false))
            dispatch(setRouteId(null))
        }
    },[])

    useEffect(()=>{

        if (typeof routeId === 'undefined') {
            setIsRouteEditor(false)
            dispatch(selectLocation(undefined))
        } else {
            setIsRouteEditor(true)
        }

        if (typeof routeId !== 'undefined'
            && typeof routes.find(route=> route.id === routeId) !== 'undefined') {
            dispatch(selectRoute({routeId: routeId, user: user}))
        }
    },[routeId])

    useEffect(()=>{

        setLocalRoute(route_)
    },[route_])

    useEffect(()=>{

        if (typeof routeLocationIds === 'undefined'
            || typeof route_ === 'undefined'
            || typeof localRoute === 'undefined') {
            setIsRouteChanged(false)
            setLocalRoute(route_)
            return
        }

        if (route_.locationIds.length !== localRoute.locationIds.length) {
            setIsRouteChanged(true)

            setLocalRoute(route_)
        }
    },[routeLocationIds])


    function showLocationDeleteInfo() {

        Modal.confirm({
            title: `Location: ${location_.name}`,
            icon: <ExclamationCircleOutlined />,
            content: 'Do you Want to delete these location?',
            onOk() {
                onDelete()
            }
        });
    }
    function onChangeRouteInfo(info) {
        dispatch(updateRouteLocalChanges({
            route: {
                ...route_,
                name: info.name,
                description: info.description,
                src: info.src
            },
            user: user
        }))

        setIsRouteChanged(true)
    }

    function onSelectLocation(id) {
        dispatch(selectLocation(id))
    }

    function onSave() {
        locations.forEach(loc=>{
            if (!loc.routeIds.includes(route_.id)) {
                let tmpLocation = {...loc}
                let tmpLocations = [...loc.routeIds]
                tmpLocations.push(route_.id)

                tmpLocation.routeIds = tmpLocations

                dispatch(updateLocation({
                    user: user,
                    data: tmpLocation}))
            }
        })

        dispatch(updateRoute({
            route: route_,
            user: user
        }))

        setIsRouteChanged(false)
    }

    function onEdit() {
        dispatch(editLocation(!showLocationEditor))
    }

    function onEnable() {
        const locData =  {...location_, isActive: true}
        dispatch(updateLocation({user: user, data: locData}))
    }

    function onDisable() {
        const locData =  {...location_, isActive: false}
        dispatch(updateLocation({user: user, data: locData}))
    }

    function onDelete() {
        dispatch(deleteLocation({user: user, location: location_}))
    }

    function onClickCancelEdit() {
        dispatch(editLocation(false))
    }

    function onClickOkEdit(data) {
        dispatch(updateLocation({user: user, data: data}))
    }

    function onClickUp(id) {
        dispatch(locationUp({locationId: id, locationIds: route_.locationIds}))
        setIsRouteChanged(true)
    }

    function onClickDown(id) {
        dispatch(locationDown({locationId: id, locationIds: route_.locationIds}))
        setIsRouteChanged(true)
    }

    function onClickCreateNewRoute() {
        dispatch(createRoute({user}))
    }

    return (
        <div>
            <Row >
                <Col sm={12} md={6}  lg={6} >
                    <div style={{overflow: 'auto', height: '83vh'}}>

                        {isRouteEditor &&
                            <div>
                                <Tabs defaultActiveKey="1" >
                                    <TabPane tab="Rout Info" key="1">
                                        <RouteSettings
                                            route={route_}
                                            onChange={(info)=>onChangeRouteInfo(info)}
                                        />
                                    </TabPane>

                                    <TabPane tab="Locations" key="2">
                                        {/* Locations list */}

                                        <div style={{overflow: 'auto', height: '76vh', width: '100%'}}>
                                            <GeoReferenceList
                                                locations={routeLocationIds.map(id=>locations.find(location => location.id === id))}
                                                selectedLocationId={selectedLocationId}
                                                onSelectItem={(index)=>onSelectLocation(index)}
                                                onEdit={()=>onEdit()}
                                                onLocationUp={(id)=>onClickUp(id)}
                                                onLocationDown={(id)=>onClickDown(id)}
                                            />
                                        </div>

                                    </TabPane>

                                </Tabs>
                            </div>
                        }

                        {!isRouteEditor &&
                            <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                                <Button block
                                        type="primary"
                                        onClick={()=>onClickCreateNewRoute()}>
                                    Create new route
                                </Button>
                            </div>
                        }
                    </div>

                    {isRouteEditor &&
                        <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                            <Button block
                                    type="primary"
                                    style={!isRouteChanged ? {backgroundColor: "#d9f7be", borderColor: "#b7eb8f"} : {backgroundColor: "#52c41a", borderColor: "#389e0d"}}
                                    disabled={!isRouteChanged}
                                    onClick={()=>onSave()}
                            >
                                Save route changes
                            </Button>
                        </div>
                    }
                </Col>

                <Col sm={12} md={18} lg={18}>

                    {/* Location information window */}
                    { location_ &&
                        <div style={{position: "absolute",
                            left: '50%', transform: 'translate(-50%)',
                            bottom: '2vh', zIndex: 1000}}>
                            <LocationMapInfoSimple
                                location={location_}
                                onEdit={()=>onEdit()}
                                onEnable={()=>onEnable()}
                                onDisable={()=>onDisable()}
                                onDelete={()=>showLocationDeleteInfo()}
                            />
                        </div>
                    }

                    {/* Location edit and info window */}
                    {location_ &&
                        <LocationInfoModal
                            location={location_}
                            visible={showLocationEditor}
                            onOk={(location) => onClickOkEdit(location)}
                            confirmLoading={true}
                            onCancel={() => onClickCancelEdit()}
                        />
                    }

                    {/* Map with markers and paths */}
                    <RouteEditMap isEditMode={true} style={{height: '88vh'}}/>
                </Col>
            </Row>
        </div>
    )
}
