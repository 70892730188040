//@flow
import React, {useEffect} from 'react';
import { Routes, Route } from "react-router-dom";

import "antd/dist/antd.css";
import WelcomeView from "./views/public/welcome_view/WelcomeView";
import UserSignInView from "./views/public/user_sign_in_view/UserSignInView";
import UserLogInView from "./views/public/user_log_in_view/UserLogInView";

import {FirebaseController, User} from "schnitzel-mc"

import UserArea from "./views/private/user_area/UserArea";

const emulatorSettings = {
    host: "localhost",
    firestorePort: 8080,
    authPort: 9099
}

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYYmIeYpFfcp2Ogkgjv7AGEwg_jFCmkYo",
    authDomain: "geolocations-01.firebaseapp.com",
    databaseURL: "https://geolocations-01-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "geolocations-01",
    storageBucket: "geolocations-01.appspot.com",
    messagingSenderId: "1091545108183",
    appId: "1:1091545108183:web:cec6e6ae5aea2e2e5caa92",
    measurementId: "G-91BMVH92TP"
};

export default function App(props) {

    return (
        <Routes>
            <Route index element={<WelcomeView/>}/>
            <Route path="login" element={<UserLogInView/>}/>
            <Route path="signin" element={<UserSignInView/>}/>

            <Route path="user/*" element={<UserArea/>}/>
        </Routes>
    )
}
