import React, {Component, useEffect, useState} from "react";
import {Avatar, Image, Card, Modal, Button, Space} from 'antd';

import {
    CheckCircleOutlined,
    DeleteOutlined,
    EnvironmentOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    SettingOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';


import {useDispatch, useSelector} from 'react-redux'

import {
    deleteLocation,
    selectLocation,
} from '../../../redux/locationsSlice';


const {Meta} = Card;

const COLOR_SELECTED = '#e8f5ff'

function LocationInfoCard(props) {
    const [isSelected, setSelected] = useState(false);
    const [location, setLocation] = useState(null);
    const [position, setPosition] = useState(undefined);

    const selectedLocationId = useSelector(state => state.locationSlice.selectedLocationId)
    const dispatch = useDispatch()

    useEffect(() => {
        setPosition(props.position)
    }, [props.position])

    useEffect(() => {
        setLocation(props.location)
    }, [props.location])

    useEffect(() => {
        if (typeof props.isSelected !== 'undefined') {
            setSelected(props.isSelected)
        }
    }, [props.isSelected])

    function onClickUp(e) {
        e.stopPropagation()

        if (typeof props.onClickUp === 'undefined')
            return

        props.onClickUp(location.id)
    }

    function onClickDown(e) {
        e.stopPropagation()

        if (typeof props.onClickDown === 'undefined')
            return

        props.onClickDown(location.id)
    }


    function onClickCard(e) {

        if (location.id === selectedLocationId) {
            dispatch(selectLocation({selectedLocationId: null}))
        } else {
            dispatch(selectLocation({selectedLocationId: location.id}))
        }

        setSelected(!isSelected)

        if (typeof props.onClick !== 'undefined') {
            props.onClick(location.id)
        }
    }

    function getAvatar() {
        if (location === null || typeof location.avatar === 'undefined') {
            return <Avatar size={64} icon={<EnvironmentOutlined/>}/>
        }

        return <Avatar size={64}
                       src={<Image src={location.avatar}/>}
        />
    }

    // Return empty space element after deletion
    if (typeof location === 'undefined') {
        return ''
    }

    return (
        <div>
            <Card style={isSelected ? {
                marginTop: 16,
                backgroundColor: COLOR_SELECTED,
                } : {marginTop: 16,}}

                  hoverable
            >

                {/* Content */}
                <div style={{margin: -24, padding: 24,}}
                     onClick={(e) => onClickCard(e)}
                >
                    <Meta
                        avatar={
                            getAvatar()
                        }
                        title={(typeof props.location === 'undefined' || typeof props.location.name === 'undefined') ? '' : props.location.name}
                    />

                    <div style={{display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        width: "100%",
                        marginTop: 10
                    }}
                    >
                        {/* Position */}
                        <div style={{display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                        >
                            {position &&
                                <div style={{display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 14,
                                    height: 35, width: 35, borderRadius: '50%',
                                    backgroundColor: isSelected ? '#1890ff'  : '#ccc',
                                    color: '#ffffff',
                                    lineHeight: '35px', fontWeight: "bold"}}>
                                    {position}
                                </div>
                            }

                            <div style={{marginLeft: 16, marginRight: 16}}>
                                <Space direction={'vertical'} align={'center'}>
                                    <Button type="primary" icon={<CaretUpOutlined/>}
                                            disabled={isSelected ? false : true}
                                            onClick={(e) => onClickUp(e)}></Button>
                                    <Button type="primary" icon={<CaretDownOutlined/>}
                                            disabled={isSelected ? false : true}
                                            onClick={(e) => onClickDown(e)}></Button>
                                </Space>
                            </div>
                        </div>

                        {/* Description */}
                        <div style={{width: "100%"}}>
                            {(typeof props.location === 'undefined' || typeof props.location.description === 'undefined') ? '' : props.location.description}
                        </div>
                    </div>

                </div>
            </Card>

        </div>
    )
}

LocationInfoCard.defaultProps = {
    onPositionUp: ()=>{},
    onPositionDown: ()=>{},
};


export default LocationInfoCard
