import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {FirebaseController, Rank, Role, User} from "schnitzel-mc"

export const SLICE_NAME = "user"

const emulatorSettings = {
    host: "localhost",
    port: 8080,
    authPort: 9099
}

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYYmIeYpFfcp2Ogkgjv7AGEwg_jFCmkYo",
    authDomain: "geolocations-01.firebaseapp.com",
    databaseURL: "https://geolocations-01-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "geolocations-01",
    storageBucket: "geolocations-01.appspot.com",
    messagingSenderId: "1091545108183",
    appId: "1:1091545108183:web:cec6e6ae5aea2e2e5caa92",
    measurementId: "G-91BMVH92TP"
};

const dbController = new FirebaseController(firebaseConfig)

const initialState = {
    user: undefined,
    loading: false,
}


const testUser = undefined

export const registerUser = createAsyncThunk(
    'user/registerUser',
    async (user) => {
        const {email, password, name} = user

        const response = await dbController.register(email, password, name)
        return response.toJson()
    }
)

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (userName, {getState}) => {

        // Get user from the store
        const state = getState()[SLICE_NAME];
        const user = state.user;

        // Check user information
        if (typeof user !== 'undefined' && user.id !== "") {
            return user
        }

        // Get user from the DB
        const response = await dbController.getUser(userName)
        return response.toJson()
    }
)
export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (user) => {

        // Check user information
        if (typeof user === 'undefined' || user === null) {
            return user
        }

        const tmpUser = new User(user)

        await dbController.updateUser(tmpUser)

        return tmpUser.toJson()
    }
)

function getInitState() {

    return {
        ...initialState,
        user: testUser
    }
}


export const userSlice = createSlice({
    name: 'user',
    initialState: getInitState(),

    reducers: {
        update: (state, action) => {
            state.user = {...action.payload}
        },

        logoutUser: (state, action) => {
            state.user = undefined
        }
    },

    extraReducers: {
        // createUser
        [registerUser.pending]: (state) => {
            console.log("CreateUser - pending")
            state.loading = true
        },
        [registerUser.fulfilled]: (state, {payload}) => {
            console.log("CreateUser - fulfilled")
            state.user = payload
            state.loading = false
        },
        [registerUser.rejected]: (state) => {
            console.log("CreateUser - rejected")
            state.loading = false
        },

        // getUser
        [loginUser.pending]: (state) => {
            console.log("GetUer - pending")
            state.loading = true
        },
        [loginUser.fulfilled]: (state, {payload}) => {
            console.log("GetUer - fulfilled")
            state.user = payload
            state.loading = false
        },
        [loginUser.rejected]: (state) => {
            console.log("GetUer - rejected")
            state.loading = false
        },

        // getUser
        [updateUser.pending]: (state) => {
            console.log("updateUser - pending")
            state.loading = true
        },
        [updateUser.fulfilled]: (state, {payload}) => {
            console.log("updateUser - fulfilled")
            state.user = payload
            state.loading = false
        },
        [updateUser.rejected]: (state) => {
            console.log("updateUser - rejected")
            state.loading = false
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    update,
    logoutUser
} = userSlice.actions
export default userSlice.reducer
